












import { Component, Prop } from 'nuxt-property-decorator';
import Vue from 'vue';
import { BlokContainerBoxEnding } from '~/types';

@Component
export default class ContainerBoxEnding extends Vue {
  @Prop() blok!: BlokContainerBoxEnding;
}
